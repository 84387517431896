<template>
  <div class="">
    <contacts-section />
  </div>
</template>

<script>
import ContactsSection from '@/components/ContactsSection.vue';

export default {
  name: 'Contact',
  components: {
    ContactsSection,
  },
  data() {
    return {
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.contact {
  background: $primary;

  .contacts {
    padding: 200px 0 70px;

    @include breakpoint(0, $lg) {
      padding: 140px 0 35px;
    }

    @include breakpoint(0, $lg) {
      padding: 100px 0 25px;
    }
  }

  .footer {
    background-color: $purple-600;

    @include breakpoint(0, $lg) {
      background-color: $light;
    }
  }
}
</style>
